import "./SubmissionPage.css";

interface Props {
  isQualified: boolean;
}

export const SubmissionPage = ({ isQualified }: Props) => {
    return (
      <div className="submissionWrapper">
        {isQualified ? (
          <>
            <h1>Anfrage erfolgreich abgeschickt</h1>
            <p>Sie erhalten eine Bestätigung per E-Mail.</p>
          </>
        ) : (
          <>
            <h1>Es tut uns leid – Wir können Ihnen derzeit kein Angebot machen.</h1>
            <p>Vielen Dank für Ihr Interesse an einer eigenen Solaranlage von Qcells. Derzeit können wir leider nur  Hauseigentümer:innen ein Angebot machen, deren Dächer neu sind oder in den letzten 30 Jahren saniert wurden. Das hängt mit rechtlichen Grundlagen und sicherheitstechnischen Kriterien zusammen. Sollten sich diese ändern, sind wir gerne mit einer personalisierten Photovoltaik-Lösung an Ihrer Seite. </p>
            <p><b>Möchten Sie dennoch auf saubere Energie umstiegen?</b> Mit unserem CO₂-freundlichen Ökostrom kein Problem. Zertifiziert und zuverlässig. Mehr Informationen dazu auf unserer Website.</p>
            <button className="linkButton"><a target="_blank" rel="noreferrer" href="https://www.q-cells.de/privatkunden/oekostrom/uebersicht" className="unqualifiedLink">Ökostrom Tarife entdecken</a></button>
          </>
        )}
      </div>
  );
};
