import "./Navbar.css";

const Navbar = () => {
  const redirectToHomepage = () => {
    window.location.href =
      "https://www.q-cells.de/privatkunden/solaranlage/eigene-solaranlage";
  };

  return (
    <nav className="navbar">
      <div>
        <img
          src="/Qcells_Wordmark.png"
          alt="Logo"
          width={117.25}
          height="auto"
          onClick={redirectToHomepage}
          className="navbar-logo"
        />
      </div>
    </nav>
  );
};

export default Navbar;
