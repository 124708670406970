import { yupResolver } from "@hookform/resolvers/yup";
import bcrypt from "bcryptjs-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { schema } from "../../utils/validationSchema";
import { SubmissionPage } from "../SubmissionPage/SubmissionPage";
import "./Form.css";

interface FormType {
  salutation: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  postcode: string;
  city: string;
  terms: boolean;
  roofType?: number;
  buildingType: string;
  ownership: boolean;
  energyConsumption?: string;
  installationAddress?: string;
  installationPostcode?: string;
  installationCity?: string;
  constructionAge: number;
  renovated: number;
  roofOccupancy: number;
  salesPartnerName?: string;
  salesPartnerEmailList?: string[];
}

type FormProps = {
  tabletLocation?: string;
  salesPartnerName?: string;
  salesPartnerEmailList?: string[];
};

const salutationOptions = [
  { value: 1, label: "Herr" },
  { value: 2, label: "Frau" },
  { value: 3, label: "Firma" },
  { value: 4, label: "Divers" },
];

const roofTypeOptions = [
  { value: 1, label: "Flachdach" },
  { value: 4, label: "Schrägdach" },
  { value: 6, label: "Spitzdach" },
];

const buildingTypeOptions = [
  { value: "eigenen-haus", label: "im eigenen Haus" },
  { value: "eigentumswohnung", label: "in einer Eigentumswohnung" },
  { value: "gemietete", label: "Gemietete Wohnung oder Haus" },
];

export const Form = ({ tabletLocation, salesPartnerName, salesPartnerEmailList }: FormProps) => {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isQualified, setIsQualified] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormType>({ resolver: yupResolver(schema) });

  const ownership = watch("ownership");
  const renovated = watch("renovated");
  const buildingType = watch("buildingType");

  useEffect(() => {
    if (ownership === false  || buildingType !== 'eigenen-haus') {
      setIsQualified(false);
    } else {
      setIsQualified(true);
    }
  }, [ownership, renovated, buildingType]);

  const handleToggle = () => {
    setIsToggleOn(!isToggleOn);
  };

  const onSubmit = async (data: FormType) => {
    var encryptHash = bcrypt.hashSync("f7ea02eb-04f1-4169-86cc-6176b2a4f886", 12);
    setIsLoading(true);
    if(isQualified){
      try {
        await fetch('https://qshowroom.azurewebsites.net/lead', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', 
            'X-API-KEY':  encryptHash,
          },
          body: JSON.stringify({ ...data, tabletLocation, salesPartnerName, salesPartnerEmailList }),
        });
        setIsSubmitted(true);
        setError(false)
      } catch (error) {
        console.log(error)
        setError(true)
      } finally {
        setIsLoading(false);
      }
    }else{
      setIsSubmitted(true);
      setError(false)
      setIsLoading(false);
    }
}

  if (isSubmitted) {
    return <SubmissionPage isQualified={isQualified}/>
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      <h1 className="title">Ihr individuelles Angebot</h1>
      <h3>Persönliche Angaben</h3>
      <div className="wrapper">
        <div className="input">
          <Controller
            control={control}
            name="salutation"
            render={({ field: { onChange, onBlur } }) => (
              <Select
                className="selectField"
                options={salutationOptions}
                onChange={(option) => onChange(option?.value)}
                onBlur={onBlur}
                placeholder={"Anrede"}
              />
            )}
          />
          {errors.salutation && (
            <p className="errorMessage">{errors.salutation.message}</p>
          )}
        </div>
        <div className="input">
          <input
            {...register("firstName")}
            className="inputField"
            placeholder="Vorname"
          />
          {errors.firstName && (
            <p className="errorMessage">{errors.firstName.message}</p>
          )}
        </div>
        <div className="input">
          <input
            {...register("lastName")}
            className="inputField"
            placeholder="Nachname"
          />
          {errors.lastName && (
            <p className="errorMessage">{errors.lastName.message}</p>
          )}
        </div>
      </div>
      <div className="wrapper">
        <div className="input">
          <input
            {...register("email")}
            className="inputField"
            placeholder="E-Mail"
          />
          {errors.email && (
            <p className="errorMessage">{errors.email.message}</p>
          )}
        </div>
        <div className="input">
          <input
            {...register("phone")}
            className="inputField"
            type="tel"
            placeholder="Telefon"
          />
          {errors.phone && (
            <p className="errorMessage">{errors.phone.message}</p>
          )}
        </div>
      </div>
      <div className="wrapper">
        <div className="input">
          <input
            {...register("address")}
            className="inputField"
            placeholder="Straße & Nr."
          />
          {errors.address && (
            <p className="errorMessage">{errors.address.message}</p>
          )}
        </div>
        <div className="input">
          <input
            {...register("postcode")}
            className="inputField"
            type="number"
            placeholder="PLZ"
          />
          {errors.postcode && (
            <p className="errorMessage">{errors.postcode.message}</p>
          )}
        </div>
        <div className="input">
          <input
            {...register("city")}
            className="inputField"
            placeholder="Ort"
          />
          {errors.city && <p className="errorMessage">{errors.city.message}</p>}
        </div>
      </div>
      <div className="selectWrapper">
        <div className="input">
          <label className="label">
            Wie hoch ist Ihr jährlicher Stromverbrauch?
          </label>
          <input
            {...register("energyConsumption")}
            className="inputField"
            type="number"
            placeholder="2.000 kWh"
          ></input>
        </div>
      </div>
      <h3>Angaben zum Projekt</h3>
      <div className="switchWrapper">
        <label className="switch">
          <input type="checkbox" onChange={handleToggle} checked={isToggleOn}/>
          <span className="slider round"></span>
        </label>
        <span className="addressWarning">Der geplante Installationsort der Anlage weicht von der genannten Adresse ab.</span> 
      </div>
      {isToggleOn && (
        <>
          <label className="label installation">Wo soll die PV-Anlage installiert werden?</label>
          <div className="wrapper">
            <div className="input">
              <input
                {...register("installationAddress")}
                className="inputField"
                placeholder="Straße & Hausnummer"
              />
            </div>
            <div className="input">
              <input
                {...register("installationPostcode")}
                className="inputField"
                type="number"
                placeholder="PLZ"
              />
            </div>
            <div className="input">
              <input
                {...register("installationCity")}
                className="inputField"
                placeholder="Ort"
              />
            </div>
          </div>
        </>
      )}
      <div className="radioGroup">
        <label className="label">Sind Sie Eigentümer des Gebäudes?</label>
        <div className="radioOptions">
          <input
            type="radio"
            {...register("ownership")}
            value="true"
            className="radioInput"
          />
          <label className="radioLabel">Ja</label>
          <input
            type="radio"
            {...register("ownership")}
            value="false"
            className="radioInput"
          />
          <label className="radioLabel">Nein</label>
        </div>
        {errors.ownership && (
          <p className="errorMessage">{errors.ownership.message}</p>
        )}
      </div>
      <div className="selectWrapper">
        <div className="input">
          <label className="label">Welchen Gebäudetyp möchten Sie mit einer PV-Anlage ausstatten?</label>
          <Controller
            control={control}
            name="buildingType"
            render={({ field: { onChange, onBlur } }) => (
              <Select
                className="selectField"
                options={buildingTypeOptions}
                onChange={(option) => onChange(option?.value)}
                onBlur={onBlur}
                placeholder={"Bitte auswählen"}
              />
            )}
          />
          {errors.buildingType && (
            <p className="errorMessage">{errors.buildingType.message}</p>
          )}
        </div>
      </div>
      <div className="radioGroup">
        <label className="label">Wann wurde das Gebäude errichtet?</label>
        <div className="radioColumn">
          <div>
            <input
              type="radio"
              {...register("constructionAge")}
              value={1}
              className="radioInput"
            />
            <label className="radioLabel">In den letzten 12 Monaten (Neubau)</label>
          </div>
          <div>
            <input
              type="radio"
              {...register("constructionAge")}
              value={2}
              className="radioInput"
            />
            <label className="radioLabel">Vor über 12 Monaten</label>
          </div>
        </div>
        {errors.constructionAge && (
          <p className="errorMessage">{errors.constructionAge.message}</p>
        )}
      </div>
      <div className="radioGroup">
        <label className="label">Wurde das Dach in den letzten 30 Jahren saniert?</label>
        <div className="radioOptions">
          <input
            type="radio"
            {...register("renovated")}
            value={1}
            className="radioInput"
          />
          <label className="radioLabel">Ja</label>
          <input
            type="radio"
            {...register("renovated")}
            value={0}
            className="radioInput"
          />
          <label className="radioLabel">Nein</label>
          <input
            type="radio"
            {...register("renovated")}
            value={2}
            className="radioInput"
          />
          <label className="radioLabel">Weiß ich nicht</label>
        </div>
        {errors.renovated && (
          <p className="errorMessage">{errors.renovated.message}</p>
        )}
      </div>
      <div className="radioGroup">
        <label className="label">Gewünschte Dachbelegung</label>
        <div className="radioColumn">
          <div>
            <input
              type="radio"
              {...register("roofOccupancy")}
              value={1}
              className="radioInput"
            />
            <label className="radioLabel">Volle Belegung</label>
          </div>
          <div>
            <input
              type="radio"
              {...register("roofOccupancy")}
              value={2}
              className="radioInput"
            />
            <label className="radioLabel">Optimierte Belegung nach Stromverbrauch</label>
          </div>
        </div>
        {errors.roofOccupancy && (
          <p className="errorMessage">{errors.roofOccupancy.message}</p>
        )}
      </div>
      <div className="selectWrapper">
        <div className="input">
          <label className="label">Welche Dachform hat das Gebäude?</label>
          <Controller
            control={control}
            name="roofType"
            render={({ field: { onChange, onBlur } }) => (
              <Select
                className="selectField"
                options={roofTypeOptions}
                onChange={(option) => onChange(option?.value)}
                onBlur={onBlur}
                placeholder={"Bitte auswählen"}
              />
            )}
          />
        </div>
      </div>
      <div className="termsBox">
        <div className="checkbox">
          <input
            type="checkbox"
            className="checkboxField"
            {...register("terms")}
          />
          <p className="terms">
            Ich bin damit einverstanden, dass meine personenbezogenen Daten für
            die Erstellung eines Angebots und die dazugehörige Kontaktaufnahme
            gemäß{" "}
            <a
              href="https://www.q-cells.de/datenschutz.html"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              Datenschutzerklärung
            </a>{" "}
            genutzt werden. Mein Einverständnis kann ich jederzeit widerrufen.
          </p>
        </div>
        {errors.terms && (
          <p className="errorMessage">{errors.terms.message}</p>
        )}
      </div>
      <button className="submitButton" type="submit" disabled={isLoading}>
        {isLoading ? 'Einreichen...' : 'Anfrage abschicken'}
      </button>
        {error && <p className="errorMessage submissionError">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</p>}
    </form>
  );
};
