import * as yup from "yup";

export const schema = yup.object().shape({
  salutation: yup.number().required("Dieses Feld muss befüllt sein."),
  firstName: yup.string().required("Dieses Feld muss befüllt sein."),
  lastName: yup.string().required("Dieses Feld muss befüllt sein."),
  email: yup
    .string()
    .required("Dieses Feld muss befüllt sein.")
    .matches(
      /^\S+@\S+\.\S+$/,
      "Bitte geben Sie eine gültige Email-Adresse ein."
    ),
  phone: yup
    .string()
    .required("Dieses Feld muss befüllt sein.")
    .matches(
      /(\(?([\d ]+){6,}\)?([ .\-–]?)([\d]+))/g,
      "Bitte geben Sie eine gültige Telefonnummer an."
    ),
  address: yup
    .string()
    .required("Bitte geben Sie eine gültige Adresse ein.")
    .matches(/^(.+)\s(\S+)$/, "Bitte geben Sie eine gültige Adresse ein."),
  postcode: yup
    .string()
    .required("Bitte geben Sie eine gültige Postleitzahl ein.")
    .matches(
      /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/,
      "Bitte geben Sie eine gültige Postleitzahl ein."
    ),
  city: yup.string().required("Dieses Feld muss befüllt sein."),
  buildingType: yup.string().required("Dieses Feld muss befüllt sein."),
  ownership: yup.boolean().required("Dieses Feld muss befüllt sein."),
  constructionAge: yup.number().required("Dieses Feld muss befüllt sein."),
  renovated: yup.number().required("Dieses Feld muss befüllt sein."),
  roofOccupancy: yup.number().required("Dieses Feld muss befüllt sein."),
  terms: yup
    .boolean()
    .oneOf([true], "Dieses Feld muss befüllt sein.")
    .required(),
});
