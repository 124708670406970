import { useLocation } from 'react-router-dom';
import "./App.css";
import { Form } from "./components/Form/Form";
import Navbar from "./components/Navbar/Navbar";

function App() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabletLocation = searchParams.get("tabletLocation") || "";
  const salesPartnerNameEncoded = searchParams.get("salesPartnerName") || "";
  const salesPartnerName = salesPartnerNameEncoded ? decodeURIComponent(salesPartnerNameEncoded) : "";

  const salesPartnerEmailList = [];
  for (let i = 0; ; i++) {
    const email = searchParams.get(`salesPartnerEmail${i}`);
    if (!email) break;
    salesPartnerEmailList.push(decodeURIComponent(email));
  }

  return (
    <>
      <Navbar />
      <div className="App">
        <Form
          tabletLocation={tabletLocation}
          salesPartnerName={salesPartnerName}
          salesPartnerEmailList={salesPartnerEmailList}
        />
      </div>
    </>
  );
}

export default App;
